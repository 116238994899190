<template>
  <div class="caselaw"
       ref="caselaws">
    <div class="title">
      <i class="iconfont icon-back"
         @click="goback"></i>
      <span>判例列表</span>
    </div>
    <ul class="case">
      <li v-for="(item,index) in result"
          :key="index">
        <div @click="decade(item.doc_id)">{{item.title}}</div>
        <div>
          <p>{{item.court.trim()}}</p>
          <p>/{{item.case_id.trim()}}</p>
          <p>/{{item.pub_date.trim()}}</p>
        </div>
      </li>
      <loading :showflag="like"></loading>
    </ul>
    <div class="btn"
         @click="goback">返回</div>
    <div v-if="encounter"
         class="enclose">
      <precedent :id="scan"
                 @changeflag="scandal"></precedent>
    </div>
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
import { tools } from '@/pages/Search/compoments/tool'
import loading from '@/pages/Search/compoments/loading'
import precedent from '@/pages/precedentDetail'
export default {
  components: { loading, precedent },
  props: ['query', 'isfb'],
  data () {
    return {
      result: [],
      like: 1,
      encounter: false,
      scan: null
    }
  },
  methods: {
    goback () {
      if (this.isfb) {
        this.$emit('changeflag', false)
      } else {
        this.$router.go(-1)
      }
    },
    decade (id) {
      this.scan = id
      this.encounter = true
    },
    scandal () {
      this.encounter = false
    }
  },

  mounted () {
    if (this.isfb) {
      // 法官报表 + 判例
      let pear = true
      let scroll = null
      scroll = tools.scroll(this.$refs.caselaws, (num) => {
        if (!pear) {
          return false
        }
        this.like = 1
        pear = false
        /* ?name=${this.query.name}&condition=${this.query.condition}&office=${this.query.office}&offset=${num} */
        if (this.query.office) {
          this.$axios.post(`${pydataBase}/api/lawyer/card/his`, {
            name: this.query.name,
            condition: this.query.condition,
            office: this.query.office,
            offset: num
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
            this.like = 2
            pear = true
            return false
          })
        } else if (this.query.summary) {
          /* ?name=${this.query.name}&court=${this.query.court}&summary=${this.query.summary}&offset=${num}&year=${this.query.year === '全部' ? '' : this.query.year} */
          this.$axios.post(`${pydataBase}/api/judge/card/his`, {
            name: this.query.name,
            court: this.query.court,
            summary: this.query.summary,
            offset: num,
            year: this.query.year === '全部' ? '' : this.query.year
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            pear = true
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
          })
          this.like = 2
        } else {
          /* ?name=${this.query.name}&condition=${this.query.condition}&offset=${num} */
          this.$axios.post(`${pydataBase}/api/party/card/his`, {
            name: this.query.name,
            condition: this.query.condition,
            offset: num
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            pear = true
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
            this.like = 2
          }).catch(err => {
            Promise.reject(err)
          })
        }
      })
      if (this.query.office) {
        this.like = 1
        /* ?name=${this.query.name}&condition=${this.query.condition}&office=${this.query.office} */
        this.$axios.post(`${pydataBase}/api/lawyer/card/his`, {
          name: this.query.name,
          condition: this.query.condition,
          office: this.query.office
        }).then(res => {
          this.result = res.data.result
          this.result.forEach(item => {
          })
          this.like = 2
        })
      } else if (this.query.summary) {
        this.like = 1
        /* ?name=${this.query.name}&court=${this.query.court}&summary=${this.query.summary}&year=${this.query.year === '全部' ? '' : this.$route.query.year}&offset=1 */
        this.$axios.post(`${pydataBase}/api/judge/card/his`, {
          name: this.query.name,
          court: this.query.court,
          summary: this.query.summary,
          year: this.query.year === '全部' ? '' : this.$route.query.year,
          offset: 1
        }).then(res => {
          this.result = res.data.result
          this.like = 2
        })
      } else {
        /* ?name=${this.query.name}&condition=${this.query.condition} */
        this.$axios.post(`${pydataBase}/api/party/card/his`, {
          name: this.query.name,
          condition: this.query.condition
        }).then(res => {
          this.result = res.data.result
          this.result.forEach(item => {
          })
        }).catch(err => {
          Promise.reject(err)
        })
      }
    } else {
      // 律师报表+当事人报表
      let pear = true
      let scroll = null
      scroll = tools.scroll(this.$refs.caselaws, (num) => {
        if (!pear) {
          return false
        }
        this.like = 1
        pear = false
        if (this.$route.query.office) {
          /* ?name=${this.$route.query.name}&condition=${this.$route.query.condition}&office=${this.$route.query.office}&offset=${num} */
          this.$axios.post(`${pydataBase}/api/lawyer/card/his`, {
            name: this.$route.query.name,
            condition: this.$route.query.condition,
            office: this.$route.query.office,
            offset: num
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
            this.like = 2
            pear = true
            return false
          })
        } else if (this.$route.query.summary) {
          /* ?name=${this.$route.query.name}&court=${this.$route.query.court}&summary=${this.$route.query.summary}&offset=${num}&year=${this.$route.query.year === '全部' ? '' : this.$route.query.year} */
          this.$axios.post(`${pydataBase}/api/judge/card/his`, {
            name: this.$route.query.name,
            court: this.$route.query.court,
            summary: this.$route.query.summary,
            offset: num,
            year: this.$route.query.year === '全部' ? '' : this.$route.query.year
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            pear = true
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
          })
          this.like = 2
        } else {
          /* ?name=${this.$route.query.name}&condition=${this.$route.query.condition}&offset=${num} */
          this.$axios.post(`${pydataBase}/api/party/card/his`, {
            name: this.$route.query.name,
            condition: this.$route.query.condition,
            offset: num
          }).then(res => {
            if (res.data.result.length === 0) {
              this.like = 0
              scroll()
              return
            }
            pear = true
            this.result = this.result.concat(res.data.result)
            this.result.forEach(item => {
            })
            this.like = 2
          }).catch(err => {
            Promise.reject(err)
          })
        }
      })
      if (this.$route.query.office) {
        /* ?name=${this.$route.query.name}&condition=${this.$route.query.condition}&office=${this.$route.query.office} */
        this.like = 1
        this.$axios.post(`${pydataBase}/api/lawyer/card/his`, {
          name: this.$route.query.name,
          condition: this.$route.query.condition,
          office: this.$route.query.office
        }).then(res => {
          this.result = res.data.result
          this.result.forEach(item => {
          })
          this.like = 2
        })
      } else if (this.$route.query.summary) {
        /* ?name=${this.$route.query.name}&court=${this.$route.query.court}&summary=${this.$route.query.summary}&year=${this.$route.query.year === '全部' ? '' : this.$route.query.year}&offset=1 */
        this.like = 1
        this.$axios.post(`${pydataBase}/api/judge/card/his`, {
          name: this.$route.query.name,
          court: this.$route.query.court,
          summary: this.$route.query.summary,
          year: this.$route.query.year === '全部' ? '' : this.$route.query.year,
          offset: 1
        }).then(res => {
          this.result = res.data.result
          this.like = 2
        })
      } else {
        /* ?name=${this.$route.query.name}&condition=${this.$route.query.condition} */
        this.$axios.post(`${pydataBase}/api/party/card/his`, {
          name: this.$route.query.name,
          condition: this.$route.query.condition
        }).then(res => {
          this.result = res.data.result
          this.result.forEach(item => {
          })
        }).catch(err => {
          Promise.reject(err)
        })
      }
    }
  }

}
</script>
<style lang="stylus">
.caselaw
  width 100%
  height 100%
  position relative
  overflow auto
  .title
    width 100%
    height 45px
    line-height 45px
    border-bottom 1px solid #F3F4F7
    i
      margin-left 15px
    span
      color #000000
      font-size 15px
      margin-left 15px
  .case
    width 93%
    margin 10px auto 0px
    padding-bottom 70px
    li
      border-bottom 1px solid #F3F4F7
      padding-bottom 10px
      margin-top 10px
      margin-bottom 10px
      box-sizing border-box
      overflow hidden
      margin-left 0px !important
      div:nth-child(1)
        color #000000
        font-size 15px
      div:nth-child(2)
        color #999999
        font-size 13px
        margin-top 5px
        font-size 12px
        p:nth-child(1)
          max-width 27%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(2)
          max-width 50%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
        p:nth-child(3)
          max-width 22%
          height 20px
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
          float left
  .btn
    width 100%
    height 49px
    line-height 49px
    background rgba(221, 221, 221, 1)
    text-align center
    color #ffffff
    position absolute
    bottom 0px
    font-size 16px
    position fixed
  .enclose
    position fixed
    top 0px
    left 0px
    z-index 999
    width 100%
    height 100%
    background #ffffff
</style>
