var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "caselaws", staticClass: "caselaw" }, [
    _c("div", { staticClass: "title" }, [
      _c("i", { staticClass: "iconfont icon-back", on: { click: _vm.goback } }),
      _c("span", [_vm._v("判例列表")]),
    ]),
    _c(
      "ul",
      { staticClass: "case" },
      [
        _vm._l(_vm.result, function (item, index) {
          return _c("li", { key: index }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.decade(item.doc_id)
                  },
                },
              },
              [_vm._v(_vm._s(item.title))]
            ),
            _c("div", [
              _c("p", [_vm._v(_vm._s(item.court.trim()))]),
              _c("p", [_vm._v("/" + _vm._s(item.case_id.trim()))]),
              _c("p", [_vm._v("/" + _vm._s(item.pub_date.trim()))]),
            ]),
          ])
        }),
        _c("loading", { attrs: { showflag: _vm.like } }),
      ],
      2
    ),
    _c("div", { staticClass: "btn", on: { click: _vm.goback } }, [
      _vm._v("返回"),
    ]),
    _vm.encounter
      ? _c(
          "div",
          { staticClass: "enclose" },
          [
            _c("precedent", {
              attrs: { id: _vm.scan },
              on: { changeflag: _vm.scandal },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }